@import "./variables.scss";

// Mixins
// For portrait phone and landscape phone
@mixin phone() {
  @media screen and (max-width: $phone-max-width) {
    @content;
  }
}

// For portrait phone
@mixin phone-portrait() {
  @media screen and (min-width: $phone-min-width) and (max-width: $phone-max-width) and (orientation: portrait) {
    @content;
  }
}
// For landscape phone
@mixin phone-landscape() {
  @media screen and (min-height: $phone-min-height) and (max-height: $phone-max-height) and (orientation: landscape) {
    @content;
  }
}
// For portrait tablet and landscape tablet
@mixin tablet() {
  @media screen and (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
    @content;
  }
}
@mixin tabletDown() {
  @media screen and (max-width: $tablet-max-width) {
    @content;
  }
}
// For portrait tablet
@mixin tablet-portrait() {
  @media screen and (min-width: $tablet-min-width) and (max-width: $tablet-max-width) and (orientation: portrait) {
    @content;
  }
}
// For landscape tablet
@mixin tablet-landscape() {
  @media screen and (min-height: $tablet-min-height) and (max-height: $tablet-max-height) and (orientation: landscape) {
    @content;
  }
}
// For desktop
@mixin desktop() {
  @media screen and (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
    @content;
  }
}
@mixin desktopDown() {
  @media screen and (max-width: $desktop-max-width) {
    @content;
  }
}
// Large
@mixin largeDown() {
  @media screen and (max-width: $large-max-width) {
    @content;
  }
}
@mixin large() {
  @media screen and (min-width: $large-min-width) and (max-width: $large-max-width) {
    @content;
  }
}
