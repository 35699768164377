@import "../../scss/Colors.scss";

.card-grid {
  &-item {
    padding: 8px 0;
    border-radius: 4px;
    border: solid 1px $section-border-soft;
    background-color: $section-bg;
    overflow: hidden;
    height: 100%;
    width: 100%;
    transition: filter 0.6s;

    &-content,
    &-headerbox,
    &-footer {
      padding: 0 16px;
      overflow: hidden;
    }

    &-headerbox {
      border-bottom: 1px solid $section-border-soft;
      margin-bottom: 8px;
      padding-top: 8px;
      padding-bottom: 8px;

      & &-header {
        margin-bottom: 0;
      }
    }

    &-footer {
      border-top: 1px solid $section-border-soft;
      margin-top: 8px;
      padding-top: 8px;
      text-align: right;
    }
  }

  &-item-highlighted {
    background-color: $primary;
    border-color: lighten($primary, 20%);
  }
}
