.SalesRevenue {
  &__cards-container {
    display: flex;
    flex-direction: row;
    margin-top: 1vh;
    margin-bottom: 1vh;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }

  &__Tabs {
    width: 100%;
  }

  &__Table-GroupBy {
    display: flex;
    justify-content: flex-end;
    margin: 5px 5px 21px 5px;
      > * {
        margin: 0 5px 0 5px;
      }
  }

  .ant-page-header {
    .ant-page-header-heading {
      width: 100%;
      @media screen and (max-width: 1067px) {
        display: flex;
        flex-direction: column;
        .ant-page-header-heading-left,
        .ant-page-header-heading-extra {
          width: 100%;
        }
      }
      @media screen and (min-width: 1068px) {
        .ant-page-header-heading-left {
          width: 40%;
        }
        .ant-page-header-heading-extra {
          width: 50%;
        }
      }
    }
  }
}
