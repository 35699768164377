@import "../../sass/Variables.scss";

.LanguageSelect {
  &__CurrentLang {
    svg {
      margin-right: 8px;
      font-size: 16px;
    }

    display: inline-flex;
    line-height: 16px;
    padding: 3px 0;
    text-transform: uppercase;
  }

  &__MenuItem {
    display: flex;

    svg {
      width: 30px;
    }

    span {
      margin-left: 16px;
    }
  }
}

