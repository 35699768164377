@import "../../scss/Colors.scss";

.date-picker-pager {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;

  .pager-icon {
    padding: 0 10px;
    cursor: pointer;
    display: flex;
    &:hover {
      color: $primary;
    }

    justify-content: center;
  }
  .pager-date-picker-container {
    width: 150px;
    text-align: center;
  }
}
