@import "../../scss/Variables.scss";

.gm-divider-translucent {
  background: linear-gradient(
    270deg,
    transparent 0%,
    $white 52.18%,
    transparent 100%
  );
  opacity: 0.33;
}

.gm-divider-transparent {
  background: $white;
  opacity: 0.15;
}
