.ScreenDesigner {
  width: 100%;
  min-height: 85px;

  &__Properties-content {
    padding: 0 16px;
  }

  .ant-layout-sider-trigger {
    position: absolute;
    background-color: #252529;
  }

  &__Properties-header {
    display: table;
    padding-bottom: 8px;
    width: 100%;

    .title {
      display: table-cell;
      width: 70%;
    }

    .actions {
      display: table-cell;
      text-align: right;
      width: 30%;
    }

    button {
      margin-left: 8px;
    }

    h1 {
      font-size: 1.17em;
      margin-block-start: 1em;
      margin-block-end: 1em;
    }
  }

  .Component *::after {
    display: unset;
  }

  .Component {
    overflow: hidden;

    &--header {
      height: fit-content;
      padding: 0;
    }

    &--footer {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      width: 100%;
      height: 300px;
    }

    &--content {
      position: relative;
    }

    &--options {
      height: 100%;
      display: flex;
      align-items: center;
      position: absolute;
      font-size: 16px;
      margin: 0 4%;
      z-index: 1002;
      top: 0;
      right: 0;
    }

    &--remove {
      cursor: pointer;
      display: inline-block;
    }

    &--drag {
      display: inline-block;
      cursor: grab;
      padding-left: 8px;

      &:active {
        cursor: grabbing;
      }
    }
  }
}

.zoom-50 {
  .Component {
    &--options {
      font-size: 32px;
    }
  }
}

.zoom-75 {
  .Component {
    &--options {
      font-size: 24px;
    }
  }
}
