.NotificationUsersModal {
  .Heading {
    padding-bottom: 0px;
    
    &_Actions {
      margin-right: 24px;
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    &_Search {
      flex-grow: 1;
      
      button {
        margin-left: 0;
      }
    }

    &_Consents {
      &_Select {
        flex-grow: 1;
      }
    }
  }
}
