@import "../../sass/Variables.scss";

.sidebar {
  &-collapsed {
    .sidebar-footer {
      .app-icon,
      .app-name {
        display: none;
      }
    }
  }

  &-footer {
    font-size: 16px;
    line-height: 32px;
    margin: 0 0 16px 0;
    color: $text-color;

    .app-icon {
      margin-right: 12px;
      font-size: 22px;
      width: 100px;
    }

    .ant-divider-horizontal {
      margin: 0 0 8px 0;
      border-top: 1px solid $text-color;
    }
  }

  &-trigger-container {
    font-size: 12px;
    padding: 0 12px;
    cursor: pointer;
    transition: color 0.3s;
    color: $text-color;
    text-align: right;
  }

  &-collapsed &-trigger-container {
    text-align: center;
  }
}
