.RatingTile {
  &_SpinerContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 150px;
  }

  &_RateContainer {
    font-size: 20px;
    display: flex;

    .RateCounter {
      padding-left: 16px;
    }
  }

}
