.ApplicationMenuPreview {
  padding: 0;
  background-color: #252529;
  position: relative;
  min-height: 70px;
  height: fit-content;

  &__AppleTVMenu {
    background-color: #17171b;
    border-radius: 50px;
    position: relative;
    height: 40px;
  }

  &.TV {
    background-color: #252529;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__WebMenu {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
  }

  &__Col {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 70px;
    box-sizing: content-box;
  }

  &__Title {
    font-weight: 900;
    font-size: 24px;
    height: 20px;
    line-height: 30px;
    width: 100%;
    text-align: center;
  }

  &__Avatar {
    width: 40px;
    height: 40px;
    font-size: 26px;
    margin: 0 20px;
  }

  &__AddButton-container {
    overflow: visible;
    position: relative;
    display: table-cell;
    text-align: center;
    width: 20%;
  }

  &__AddButton {
    background-color: red;
    width: 40px;
    height: 40px;
    position: absolute;
    top: -20px;
    left: 50%;
    transform: translateX(-50%);
  }

  &__Items {
    display: table;
    max-height: 70px;
    width: 100%;

    .Component {
      display: table-cell;
      width: 20%;
      text-align: center;
      height: 60px;
      vertical-align: middle;
      padding: 8px;

      &--content {
        position: relative;
      }

      &--options {
        display: inline-block;
        position: absolute;
        font-size: 16px;
        padding: 4px 4px;
        z-index: 1002;
        top: 0;
        right: 0;
      }
    }

    &--Dropdown-Title {
      margin: 0 4px;
      font-size: 24px;
      font-weight: 900;
      line-height: 14px;
      display: block;
      cursor: pointer;
    }

    &--Dropdown-Menu {
      background-color: #252529;
    }
  }
}
