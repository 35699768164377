.UserLockModal {
  &_Spin {
    .ant-spin-container {
      &:after {
        background: transparent;
      }
    }
  }
}

.UserLockForm {
  text-align: left;
}
