.ApplicationScreenComponents {
  &__Table-header {
    display: table;
    padding-bottom: 8px;
    width: 100%;

    .title {
      display: table-cell;
      width: 50%;
    }

    .actions {
      display: table-cell;
      text-align: right;
      width: 50%;
    }

    button {
      margin-left: 8px;
    }
  }

  &__Table-content .ant-table-content {
    overflow-x: auto;
  }
}
