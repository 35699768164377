@import "./Colors.scss";

$primary-color: #1890ff;

$primary-text-color: #ffffff;
$text-color: fade-out($primary-text-color, 0.65);

$header-bg: #2e2e2e;
$input-bg: #2e2e2e;

$layout-body-background: #121212;

$screen-xs: 480px;
$screen-xs-min: $screen-xs;

$container-padding-vertical: 30px; // top and bottom
$container-padding-horizontal: 30px; // left and right
$container-padding: $container-padding-vertical $container-padding-horizontal;

$font-size-base: 14px;
$font-size-lg: $font-size-base + 2px;
$font-size-sm: 12px;

$heading-1-size: 3rem;
$heading-1-line-height: 3.625rem;
$heading-1-font-weight: 500;

$heading-2-size: 2.5rem;
$heading-2-line-height: 2.875rem;
$heading-2-font-weight: 500;

$heading-3-size: 1.875rem;
$heading-3-line-height: 2.25rem;
$heading-3-font-weight: 500;

$heading-4-size: 1.75rem;
$heading-4-line-height: 1.875rem;
$heading-4-font-weight: 400;

$component-background: #1c1c1c;
$body-background: #1c1c1c;
$border-radius-base: 2px;

// Tmp solution, problem with less -> sass
// Small screen / tablet
$screen-sm: 576px;
$screen-sm-min: $screen-sm;

// Medium screen / desktop
$screen-md: 768px;
$screen-md-min: $screen-md;

// Large screen / wide desktop
$screen-lg: 992px;
$screen-lg-min: $screen-lg;

// Extra large screen / full hd
$screen-xl: 1200px;
$screen-xl-min: $screen-xl;

// Extra extra large screen / large desktop
$screen-xxl: 1600px;
$screen-xxl-min: $screen-xxl;

// provide a maximum
$screen-xs-max: ($screen-sm-min - 1px);
$screen-sm-max: ($screen-md-min - 1px);
$screen-md-max: ($screen-lg-min - 1px);
$screen-lg-max: ($screen-xl-min - 1px);
$screen-xl-max: ($screen-xxl-min - 1px);

$base-border: 1px solid $gray-1;

// Vertical paddings
$padding-lg: 24px; // containers
$padding-md: 16px; // small containers and buttons
$padding-sm: 24px; // Form controls and items
$padding-xs: 8px; // small items

// Border
$border-color-base: #828282;
$border-width-base: 2px;
$border-style-base: solid;

// Animation
$animation-duration-slow: 0.3s; // Modal
$animation-duration-base: 0.2s;
$animation-duration-fast: 0.1s; // Tooltip

// Input
// ---
$input-square-border-radius-round: 10px;
$input-border-radius-base: 30px;
$input-border-radius-round: 30px;
$input-icon-size: 20px;

$layout-sider-background: #232323;
$layout-sider-item-color: #686868;
$layout-sider-item-selected-color: $white;
$layout-sider-item-selected-background: #303034;
