@import "../../sass/Variables.scss";

$header-hover-bg: darken($header-bg, 5%);

.navbar {
  background: $header-bg;
  padding: 0 16px;
  user-select: none;
  box-shadow: 0 4px 5px 0 #00000055;
  position: relative;
  z-index: 1;

  .ant-row {
    height: 64px;
    line-height: 1rem;
  }

  &-action {
    display: inline-block;
    height: 100%;
    padding: 0 12px;
    cursor: pointer;
    transition: all 0.3s;

    &:hover {
      background: $header-hover-bg;
    }
  }

  &-avatar:hover {
    cursor: pointer;
  }

  &-right {
    float: right;
    height: 100%;
    overflow: hidden;
  }

  .ant-btn-link {
    color: #ffffff99;
  }
}

.navbar-logout-icon {
  padding-right: 12px;
}
