.ApplicationScreenDetails {
  &__Properties {
    padding-left: 16px;
    > .ant-layout-sider-children {
      padding-top: 0;
    }
    &--header {
      padding: 16px;
    }

    &--content {
      padding: 16px;
    }
  }
}
