.UserBrowserModal {
  .Heading {
    padding-bottom: 0px;

    h1 {
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
    }
  }

  &-column-user {
    display: table;

    &-avatar {
      display: table-cell;
      vertical-align: middle;
    }

    &-name {
      display: table-cell;
      padding: 0 0 0 16px;
      vertical-align: middle;
      max-width: 300px;

      p {
        margin-bottom: 4px;
      }
    }

    &-email {
      max-width: 280px;
    }

    &-phone-number {
      max-width: 160px;
    }
  }
}
