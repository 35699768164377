@import "../../scss/Variables.scss";

h1.ant-typography,
.ant-typography h1 {
  font-weight: $heading-1-font-weight;
  line-height: $heading-1-line-height;
  margin-bottom: 0;
}

h2.ant-typography,
.ant-typography h2 {
  font-weight: $heading-2-font-weight;
  line-height: $heading-2-line-height;
  margin-bottom: 0;
}

h3.ant-typography,
.ant-typography h3 {
  font-weight: $heading-3-font-weight;
  line-height: $heading-3-line-height;
  margin-bottom: 0;
}

h4.ant-typography,
.ant-typography h4 {
  font-weight: $heading-4-font-weight;
  line-height: $heading-4-line-height;
  margin-bottom: 0;
}
