.asetImagePreview {
  max-width: 100px;
  max-height: 56px;
}

.AssetChildrenList {
  &__actions {
    display: inline-flex;
    width: 60%;

    .right {
      width: 100%;
      text-align: right;
    }
  }
}
