.SubscriptionListTable {

  .ant-table-content {
    overflow-x: auto;
  }

  .columnWithEllipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .SubscriptionKey {
    max-width: 100px;
  }

  .ProductColumn {
    max-width: 250px;
  }

  th {
    white-space: nowrap;
  }

  .nowrapOnHyphen {
    white-space: nowrap;
  }
}