.ApplicationResourceUploadModal {
  .ant-upload.ant-upload-drag {
    position: relative;
    width: 100%;
    height: 200px;
  }

  .ant-upload.ant-upload-drag .ant-upload {
    padding: 0;
    margin: 0;
  }

  .ant-upload-drag-container {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .incorrect-aspect-ratio-msg {
    display: flex;
    gap: 10px;

    svg {
      font-size: 30px;
    }
  }

  &_Dagger {
    &_Preview {
      width: 100%;
      height: 100%;
      position: relative;
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        max-width: 100%;
        width: auto;
        height: auto;
        max-height: 200px;
      }
    }
  }
}
