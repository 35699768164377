.palette-wrapper {
  width: 80%;
  margin-left: 0;
  margin-right: auto;
  justify-content: left;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(10px, max-content));

  & .palette-item {
    box-sizing: content-box;
    display: inline-block;
    height: 32px;
    width: 32px;
    border-radius: 24px;
    border: 4px solid #202020;
  }
}
