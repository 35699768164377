.ant-pagination {
  .ant-pagination-item,
  .ant-pagination-prev,
  .ant-pagination-next,
  .ant-pagination-jump-prev,
  .ant-pagination-jump-next {
    margin: 0 2px;
  }

  .ant-pagination-options {
    margin: 0 0 0 8px;
  }

  .ant-pagination-item,
  .ant-pagination-item-link,
  .ant-select,
  .ant-pagination-options .ant-select-selector {
    background-color: #2e2e2e;
    border-radius: 6px;
    font-weight: 600;
  }
}

.ant-pagination {
  flex-grow: 0;
  flex-shrink: 0;
  align-self: flex-end;
  padding: 8px 16px;
  background-color: #3e3e3e;
  border-top-left-radius: 16px;
}
.section-grid-item .ant-pagination {
  background: none;
  padding: 0;
}
