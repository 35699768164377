.VoucherCreateForm {
  text-align: left;

  .HalfWidthItem {
    display: flex;
    column-gap: 8px;
    & > * {
      flex: 1;
    }
  }
}
