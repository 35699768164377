@import "../../scss/Colors.scss";

.ant-btn:not(.ant-btn-link) {
  text-transform: uppercase;
}

.ant-btn-action {
  color: $white;
  border: none;
  background-image: linear-gradient(to right, #f85662 0%, #e38259 100%);
  font-size: 12px;
  font-weight: 400;

  &:hover,
  &:focus {
    background-image: linear-gradient(to right, #f85662 0%, #e38259 100%);
    opacity: 0.8;
  }
}
