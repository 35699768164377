.ant-menu,
.ant-menu-dark,
.ant-menu-light,
.ant-menu-submenu > .ant-menu {
  background: none;
}

.ant-menu-inline-collapsed > .ant-menu-item,
.ant-menu-inline-collapsed
  > .ant-menu-item-group
  > .ant-menu-item-group-list
  > .ant-menu-item,
.ant-menu-inline-collapsed
  > .ant-menu-item-group
  > .ant-menu-item-group-list
  > .ant-menu-submenu
  > .ant-menu-submenu-title,
.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
  border-radius: 50px;
  padding: 0 22px;
  margin: 4px 8px;
}

.ant-menu-inline .ant-menu-item,
.ant-menu-item-group {
  border-radius: 0 50px 50px 0;
  margin: 4px 0;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: change-color($primary-color, $alpha: 0.05);
}

.ant-menu:not(.ant-menu-horizontal)
  .ant-menu-item-active:not(.ant-menu-item-selected) {
  color: white;
  background-color: lighten($layout-body-background, 10%);
}

.ant-menu-item-selected {
  color: $primary-color;
  & > * {
    color: inherit;
  }
}
.ant-menu-item:hover {
  color: white;
}

.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  border-right: 0;
}

.ant-menu,
.ant-menu-dark,
.ant-menu-light {
  border-right: 0;
  font-weight: 500;
}

.ant-menu-submenu .anticon svg,
.ant-menu-item .anticon svg {
  font-size: 20px;
  margin-bottom: -2px;
}

.ant-menu-submenu-popup {
  background-color: $layout-body-background;
}
