@import "@bms/common-ui/src/scss/Common.scss";

.PlayListComponentPreview {
  position: relative;
  width: 100%;
  height: 100%;

  &--content {
    overflow-y: auto;
    overflow-x: hidden;
    height: 90%;
    width: 100%;
    @include scrollbar-theme-dark();
  }
}
