.ApplicationHeaderItemPreview {
  width: 30px;
  height: 30px;
  text-align: center;
  padding-top: 2px;

  &__Icon {
    width: 18px;
    height: 18px;
    margin: 0 4px;
  }

  &__Title {
    margin: 0 4px;
  }
}

.WithTitle {
  width: auto;
}
