.ColorPicker {
  padding-top: 5px;

  &-background {
    padding: 4px;
    background-color: #ffffff;
    border-radius: 2px;
    display: inline-block;
    cursor: pointer;
  }

  &__SketchPicker {
    input,
    span {
      line-height: normal;
      color: #000000;
    }
  }
}
