.code-input {
  margin-bottom: 15px;

  input {
    border: transparent;
    border-bottom: 2px solid #d9d9d9;
    border-radius: 0;
    width: 40px;
    height: 50px;
    margin: 0 10px;
    text-align: center;
    background: transparent;
    font-size: 1.5rem;
    color: #000000;

    &:hover,
    &:focus {
      border: transparent;
      border-bottom: 2px solid #060606;
    }

    &:focus {
      box-shadow: none;
      outline: none;
    }
  }
}
