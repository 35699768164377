.ListComponentPreview {
  position: relative;
  width: 100%;

  &--content {
    display: flex;
  }

  &--title {
    min-height: 28px;

    h4 {
      margin-bottom: 10px;
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;
      padding-left: 8px;
    }
  }
}
