@import "@bms/common-ui/src/scss/Variables.scss";

.AssetPreview {
  width: 100%;
  height: 100%;
  position: relative;

  &__article {
    position: relative;
    width: 95%;
    margin: 2% 5%;
    border: 0px;
    scroll-behavior: smooth;
  }

  &__background {
    width: 100%;
    position: absolute;
  }

  &__overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(
      to bottom,
      rgba($layout-body-background, 0.15),
      $layout-body-background
    );
  }

  & img {
    width: 100%;
    height: auto;
  }
}
