.RatingAppDataCard {
  width: 50%;

  &__Row {
    display: flex;
    flex-direction: row;
  }

  &__Column {
    display: flex;
    flex-direction: column;
    margin: 25px;
  }

  &__RateContainer {
    font-size: 20px;
    display: flex;

    .RateCounter {
      padding-left: 16px;
    }
  }
}
