.ApplicationFooterPreview {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;

  &__Grid {
    justify-content: center;
    display: grid;
    width: 100%;
    height: 50%;
  }
}
