@import "../../scss/Colors.scss";

.row-dragging {
  background: $gray-3;
  border: 1px solid $primary;
}

.row-dragging {
  td {
    padding: 16px;
    visibility: hidden;
  }
}
.transfer-table-row td {
  padding: 8px;
}

.row-dragging .drag-visible {
  visibility: visible;
}
