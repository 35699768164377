.AssetImpressionsChart {
    min-height: 50vh;
    overflow: auto;
    margin: auto;
  
    &__spin {
      position: relative;
      margin: auto;
    }
  
    &__no-data-text {
      text-align: center;
      display: block;
      margin: auto;
    }
    
    > .ant-card-body {
      min-height: 50vh;
      position: relative;
      display: flex;
    }
  }
  