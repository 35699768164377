.ListItemCoverComponentPreview {
  border-radius: 10px;
  transition: background 0.2s, transform 0.2s;

  &.ListItemCoverComponentPreview-active {
    transform: scale(1.1);
    background: #1c1c1c;
  }

  &-image-container {
    position: relative;
  }

  &-image {
    height: 100%;
    background-repeat: no-repeat;
    background-size: 100%;

    img {
      object-fit: cover;
    }
  }

  &-status {
    position: absolute;
    padding: 0 20px;
    bottom: 20px;
    width: 100%;

    &-online-indicator {
      font-size: 1rem;
    }

    &-duration {
      font-size: 1rem;
    }
  }
}
