.TranslationsBrowserModal {
  .Heading {
    padding-bottom: 0px;

    h1 {
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
    }
  }
}
