.PaymentList {
  &__Price {
    text-align: right;
  }

  &__OriginalPrice {
    position: relative;

    &::after {
      content: "";
      width: 85%;
      height: 100%;
      display: inline-block;
      position: absolute;
      left: 0;
      bottom: 0;
      background-color: rgba(255, 255, 255, 0.65);
      clip-path: polygon(100% 0, 100% 12%, 0 100%, 0 88%);
    }
  }
}
