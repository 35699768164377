.ImagePreviewModal {
  display: block;
  min-width: 320px;
  min-height: 180px;
  max-width: 90vw;

  img {
    width: 100%;
    height: auto;
    margin: auto 0;
  }

  .spin,
  .info {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
