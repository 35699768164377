@import "../../scss/Colors.scss";

.ant-typography {
  white-space: pre-line;
}

.ant-typography-ellipsis-single-line {
  white-space: nowrap;
}

.ant-typography-contrast {
  color: $cyan;
}
