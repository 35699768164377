.branding-value-group-title {
  padding: 0.5em 0;
}

.branding-value-group-table {
  padding: 0.5em 0 2rem;

  &:first-of-type {
    // Fix color picker hidden under the table
    .ant-table {
      z-index: 100000;
    }
  }
}
