.SettingsMenuItemPreview {
  width: 80%;
  height: 47px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.Mobile {
  &.SettingsMenuItemPreview {
    &__Icon {
      width: 35px;
      height: 35px;
      border: white solid 2px;
      border-radius: 5px;
    }

    &__Title {
      width: 50%;
      font-size: 14px;
      font-weight: 500;
      text-align: center;
      display: block;
    }
  }
}

.Web {
  &.SettingsMenuItemPreview {
    height: 84px;

    &__Icon {
      width: 55px;
      height: 55px;
      border: white solid 2px;
      border-radius: 5px;
    }

    &__Title {
      width: 50%;
      font-size: 20px;
      font-weight: 500;
      text-align: center;
      display: block;
    }
  }
}

.TV {
  &.SettingsMenuItemPreview {
    &__Icon {
      width: 35px;
      height: 35px;
      border: white solid 2px;
      border-radius: 5px;
    }

    &__Title {
      width: 50%;
      font-size: 14px;
      font-weight: 500;
      text-align: center;
      display: block;
    }
  }
}
