.ant-modal {
  background-color: #3e3e3e;
  border-radius: 8px;
  border: none;
  padding: 0;
  margin-bottom: 24px;
  transition: filter 1s, background-color 1s;

  .ant-modal-body::before {
    height: 4px;
    top: -10px;
    position: relative;
    margin: -2px -24px;
    display: block;
    content: "";
  }
}

.modal-processing-overlay {
  filter: grayscale(50%) brightness(90%);

  @keyframes modal-processing-bar {
    from {
      background-position-x: 0;
    }

    to {
      background-position-x: 2000px;
    }
  }

  .ant-modal-body::before {
    background-image: linear-gradient(
      90deg,
      $primary-color 500px,
      rgb(41, 35, 35) 500px
    );
    background-size: 2000px;
    background-repeat: repeat-x;
    animation: modal-processing-bar 2s infinite linear;
  }
}

.ant-modal-header,
.ant-modal-body,
.ant-modal-footer,
.ant-modal-content {
  background: none;
  border-color: #ffffff22;
  box-shadow: none;
}

.ant-modal-body {
  padding: 8px 8px;
}

.ant-modal-footer {
  padding: 12px 8px;
}

.ant-modal-title {
  font-weight: 500;
  font-size: 24px;
}
