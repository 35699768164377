@import "../../scss/Colors.scss";

.gm-carousel {
  .slick-arrow {
    color: $white;
    transition: opacity 0.3s;
    font-size: 1.5rem;
    height: auto;
    width: auto;
    z-index: 2;
    user-select: none;

    &:focus,
    &:hover {
      color: $white;
    }

    &.slick-disabled {
      opacity: 0.25;
    }
  }

  .slick-dots {
    li {
      button {
        border-radius: 50%;
        background: $white;
      }
    }

    & {
      li.slick-active {
        button {
          border-radius: 5px;
          background: $white;
        }
      }
    }
  }
}
