.video-player-container {
  overflow: hidden;
  width: 100vh !important;
  height: 100%;
}

.video-container {
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
