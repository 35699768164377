.ApplicationFooterItemComponentPreview {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  &__Icon {
    width: 22px;
    height: 22px;
    margin: 0 8px;
  }

  &__Title {
    margin: 0 4px;
    font-size: 11px;
    font-weight: 500;
    line-height: 14px;
  }
}
