@import "@bms/common-ui/src/scss/Common.scss";

.PlayListItemComponentPreview {
  margin-bottom: 10px;
  width: 100%;

  &--image-container {
    width: 150px;
    height: 100%;
    border-radius: 8px;
    overflow: hidden;
    background: $gray;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &--info {
    line-height: 1.5;
    padding: 0 16px;

    &-title {
      font-size: 14px;
      font-weight: 700;
      letter-spacing: 0.03px;
      line-height: 21px;
    }

    &-description {
      min-width: 0;
      font-size: 14px;
      line-height: 21px;
    }
  }

  .anticon {
    font-size: 20px;
    padding: 5px;
  }
}
