.ImageWithPlaceholder {
  width: 100%;
  height: 100%;

  &--spinner {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &__img {
    width: 100%;
    height: 100%;
  }
}
