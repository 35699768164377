.ApplicationIntroPreview {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;

  video {
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  .video-play-pause-button {
    position: absolute;
    bottom: 24px;
    right: 96px;
    width: 64px;
    height: 64px;
    z-index: 1001;
  }

  .video-button-icon {
    font-size: 64px;
  }

  .video-mute-button {
    position: absolute;
    bottom: 24px;
    right: 16px;
    width: 64px;
    height: 64px;
    z-index: 1001;
  }

  .video-mute-button-icon {
    font-size: 36px;
  }
}
