@import "../../scss/Colors.scss";

.crosshair-content {
  background-color: $section-bg;
  padding: 8px;
  border-radius: 8px;

  h3 {
    border-bottom: 1px solid grey;
  }

  &-item {
    color: $white;
    width: 160px;

    &-highlighted {
      color: $primary;
      font-weight: 600;
    }
  }
}
