.UserWallet {
  &__block {
    display: flex;
    gap: 10px;
  }

  &__description {
    color: rgba(255, 255, 255, 0.85);
  }
}
