.ApplicationHeaderPreview {
  padding: 16px;

  &__Avatar {
    width: 40px;
    height: 40px;
    font-size: 26px;
  }

  &__Title {
    font-weight: 900;
    font-size: 28px;
    line-height: 40px;
    margin: 0;
  }

  &__Items {
    display: inline-flex;
    max-height: 40px;

    .Component {
      overflow: hidden;

      &--content {
        position: relative;
      }

      &--options {
        display: inline-block;
        position: absolute;
        font-size: 16px;
        padding: 4px 4px;
        z-index: 1002;
        top: 0;
        right: 0;
      }
    }

    &--Dropdown--Icon {
      font-size: 28px;
      padding-top: 2px;
    }
  }
}
