@import "@bms/common-ui/src/scss/Common.scss";

.assets-tile {
  &-list {
    width: 100%;
    max-height: 170px;
    overflow-y: scroll;
    padding: 8px;
    a {
      color: inherit;
    }
    a > div {
      cursor: pointer;
    }
  }

  &-item {
    font-weight: 600;
    cursor: default;

    &-highlighted {
      color: $primary;
    }
  }
}
