.gm-tag {
  padding: 5px 15px;
  border: none;
  font-size: 14px;
  line-height: 17px;

  &--exclusive {
    background: linear-gradient(101.31deg, #333 0%, #000 100%);
  }

  &--movie-night {
    background: linear-gradient(99.63deg, #f9a560 100%, #f95a5f 0%);
  }

  &--premiere {
    background: linear-gradient(101.86deg, #9bd264 100%, #d7e17d 0%);
  }
}
