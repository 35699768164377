.AssetImpressions {
  &__cards-container {
    display: flex;
    flex-direction: row;
    margin-top: 1vh;
    margin-bottom: 1vh;
    max-width: 80vw;
    margin-left: auto;
    margin-right: auto;
  }
  .ant-page-header {
    .ant-page-header-heading {
      width: 100%;
      @media screen and (max-width: 1067px) {
        display: flex;
        flex-direction: column;
        .ant-page-header-heading-left,
        .ant-page-header-heading-extra {
          width: 100%;
        }
      }
      @media screen and (min-width: 1068px) {
        .ant-page-header-heading-left {
          width: 40%;
        }
        .ant-page-header-heading-extra {
          width: 50%;
        }
      }
    }
  }
}
