.ant-table,
.ant-table-thead > tr > th {
  background-color: inherit;
}

.ant-table-tbody > tr > td {
  border-width: 0;
}

.ant-table-cell .ant-empty ~ .ant-btn {
  display: flex;
  margin: 0 auto;
  align-items: center;
  align-content: center;
}

// header borders
.ant-table-thead > tr > th.ant-table-cell {
  padding: 8px 16px;
  border-image-slice: 1;

  &:not(:last-child) {
    border-width: 0 2px 1px 0;
  }

  &:last-child {
    border-width: 0 0 1px 0;
  }
}

.ant-table-small .ant-table-thead > tr > th,
.ant-transfer-list-header {
  background: transparent;
}

.ant-transfer-list-header {
  padding: 0 3px 0 16px;
}

.ant-transfer-list-header-selected,
.ant-transfer-list-header-dropdown {
  display: none;
}

.ant-transfer-customize-list .ant-transfer-list {
  overflow: hidden;
}

tr.ant-table-expanded-row > td,
tr.ant-table-expanded-row:hover > td {
  border-radius: 12px;
  background: #00000055;
  border: solid 1px #ffffff33;
}

.ant-table-cell a {
  text-decoration: underline;
  text-underline-offset: 4px;

  span {
    color: inherit;
  }

  &:hover {
    span {
      color: inherit;
    }
  }
}
