// Apple Watch
$device-gray: #e2e3e4;
$device-gray-dark: darken($device-gray, 20%);
$device-gray-panel: #0d0d0d;

.device-apple-watch {
  height: 234px;
  width: 200px;

  .device-frame {
    background: $device-gray-panel;
    border-radius: 40px;
    box-shadow: inset 0 0 2px 2px $device-gray-dark,
      inset 0 0 0 6px $device-gray, inset 0 0 0 8px $device-gray;
    height: 234px;
    padding: 32px;
    position: relative;
    width: 200px;

    &::after {
      border-radius: 30px;
      box-shadow: inset 0 0 25px 0 rgba(255, 255, 255, 0.75);
      content: "";
      height: 216px;
      left: 9px;
      position: absolute;
      top: 9px;
      width: 182px;
    }
  }

  // 272-by-340-pixel resolution
  .device-content {
    border: 2px solid lighten($device-silver-panel, 2%);
    border-radius: 2px;
    height: 170px;
    width: 136px;
  }

  .device-btns {
    background: $device-gray;
    border-left: 2px solid $device-gray-dark;
    border-radius: 8px 4px 4px 8px / 20px 4px 4px 20px;
    box-shadow: inset 0 0 2px 2px $device-gray-dark;
    height: 44px;
    position: absolute;
    right: -10px;
    top: 52px;
    width: 16px;
    z-index: 9;

    &::after {
      background: $device-gray;
      border-radius: 4px 2px 2px 4px / 10px 2px 2px 10px;
      box-shadow: inset 0 0 1px 2px $device-gray-dark;
      content: "";
      height: 66px;
      right: 6px;
      position: absolute;
      top: 68px;
      width: 8px;
    }

    &::before {
      background: $device-gray-dark;
      box-shadow: 0 -16px $device-gray-dark, 0 -12px $device-gray-dark,
        0 -8px $device-gray-dark, 0 -4px $device-gray-dark,
        0 4px $device-gray-dark, 0 8px $device-gray-dark,
        0 12px $device-gray-dark, 0 16px $device-gray-dark;
      content: "";
      height: 2px;
      margin-top: -1px;
      position: absolute;
      right: 0;
      top: 50%;
      width: 6px;
    }
  }
}
