.PaymentListHeader {
  &__container {
    display: flex;
    flex-direction: column;
    &-row {
      min-width: 60vw;
      max-width: 60vw;
      display: flex;
      flex-direction: row;
      margin: 5px;
      > * {
        margin: 0 5px 0 5px;
      }
      &--reverse {
        flex-direction: row-reverse;
      }
    }
  }
}
