@import "../../scss/Variables.scss";

.gm-gradient-input-container {
  width: 100%;
  position: relative;
  z-index: 1;

  .ant-input {
    border: 0;

    &:focus {
      box-shadow: none;
    }
  }

  .gm-gradient-input-border {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
    transition: all $animation-duration-base;
  }
}
