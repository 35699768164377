.TextWidgetComponentPreview {
  min-height: 100%;

  &__content {
    white-space: pre-wrap;
    word-break: break-word;
  }

  h1,
  h2,
  h3 {
    margin: 0;
  }

  &__Header--content {
    display: flex;
    flex: 0 1 auto;
    align-items: center;
  }

  &__Title {
    flex: 1 1 auto;
    margin: 0;
  }

  .ant-divider-horizontal {
    margin-top: 10px;
    margin-bottom: 25px;
    background: rgba(255, 255, 255, 0.15);
  }
}
