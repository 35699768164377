// Reset
/*.device,
.device * {
  &,
  &::before,
  &::after {
    box-sizing: border-box;
    display: block;
  }
}*/

.device {
  position: relative;
  transform: scale(1);
  z-index: 1;

  .device-frame {
    z-index: 1;
  }

  .device-content {
    background-color: #fff;
    background-position: center center;
    background-size: cover;
    object-fit: cover;
    position: relative;
  }
}

.zoom-50 {
  -ms-zoom: 0.5;
  -moz-transform: scale(0.5);
  -moz-transform-origin: 0 0;
  -o-transform: scale(0.5);
  -o-transform-origin: 0 0;
  -webkit-transform: scale(0.5);
  -webkit-transform-origin: 0 0;
}

.zoom-75 {
  -ms-zoom: 0.75;
  -moz-transform: scale(0.75);
  -moz-transform-origin: 0 0;
  -o-transform: scale(0.75);
  -o-transform-origin: 0 0;
  -webkit-transform: scale(0.75);
  -webkit-transform-origin: 0 0;
}
