@import "@bms/common-ui/src/scss/Common.scss";
@import "./AntDesignOverrides.scss";

body {
  margin: 0;
  color: hsla(0,0%,100%,.65);
  font-size: 14px;
  font-family: -apple-system,BlinkMacSystemFont,segoe ui,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji;
  font-variant: tabular-nums;
  line-height: 1.5715;
  background-color: #121212;
  -webkit-font-feature-settings: "tnum","tnum";
  font-feature-settings: "tnum","tnum";
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.link {
  color: #1890ff;
  text-decoration: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
}

.gm-tag {
  padding: 4px 15px;
  border-radius: 4px;
}

.sidebar-and-content {
  overflow: hidden;
}

.content {
  overflow: scroll;
}

.main {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 0;
    margin-bottom: 0.5em;
    font-weight: 500;
  }

  h1 {
    font-size: 1.17em;
  }
}

h1 {
  font-size: 1.17em;
}

svg {
  fill: currentColor;
}

::-webkit-scrollbar {
  width: 12px;
  height: 12px;
  border-radius: 12px;
}

::-webkit-scrollbar-corner {
  background: none;
}

::-webkit-scrollbar-thumb {
  border-radius: 12px;
  width: 0;
  height: 0;
  background: #4e4e4e;
}

::-webkit-scrollbar-button {
  width: 0;
  height: 0;
}

::-webkit-scrollbar-thumb:hover {
  background: $primary-color;
}
