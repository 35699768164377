.StackedBarChart2 {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  &__toolbar {
    margin-top: 1vh;
    display: flex;
    flex-direction: row
  }
  &__control {
    margin: auto;
    width: fit-content;
  }
}
