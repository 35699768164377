@import "../../scss/Colors.scss";

.tile-list-item {
  border: solid 0 #333;
  border-top-width: 1px;
  padding: 4px 0 4px 0;
  &:last-child {
    border-bottom-width: 1px;
  }

  &-cover {
    margin-top: 4px;
    border-radius: 4px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 35px;
    height: 45px;
  }
  &-header {
    font-size: 12px;
  }
  &-title {
    font-size: 14px;
    & > a {
      color: inherit;
      &:hover {
        color: $primary;
      }
    }
  }
  &-year {
    font-size: 10px;
    opacity: 0.7;
  }
  &-genre {
    font-size: 10px;
    opacity: 0.7;
  }
}
