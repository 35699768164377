.PageContent {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 24px;

  > :first-child {
    flex-grow: 1;
    flex-shrink: 1;
    overflow-y: scroll;
    overflow-x: auto;
    padding-right: 16px;
  }

  .ant-page-header {
    padding-left: 0;
    padding-right: 0;
    &-heading-left {
      align-items: start;
    }
  }

  .ant-page-header-heading-title {
    span {
      color: inherit;
      font-weight: inherit;
      font-size: inherit;
      line-height: inherit;
    }
  }

  .section-grid-item {
    .ant-pagination {
      padding-right: 28px;
      margin-top: 20px;
    }
  }
}

.SalesRevenue {
  .ant-page-header {
    &-heading-extra {
      justify-content: end;
    }
  }
}
