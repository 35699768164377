.AdvertisementBoardDetails {
  video:focus {
      outline: none;
  }
  &__Properties {
    &--header {
      padding: 16px;
    }

    &--content {
      padding: 16px;
    }
  }
}
