.VoucherListTable {

  .ant-table-content {
    overflow-x: auto;
  }

  th {
    white-space: nowrap;
  }

  .nowrapOnHyphen {
    white-space: nowrap;
  }
}