.TranslationDetails {
  height: 100%;

  &__PageHeader {
    margin-bottom: 16px;
  }

  .ant-table-row {
    cursor: pointer;
  }

  &__Table-column-value {
    max-width: 512px;
  }

  &__Table-column-layout-definition-url {
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
