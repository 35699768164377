// If needed overwrite default colors for common
$primary: #0094ff;
$white: #fff;
$black: #000;
$gray: #d9d9d9;
$gray-1: #888888;
$gray-2: #333339;
$gray-3: #2e2e2e;
$gray-4: #929294;
$gray-5: #686868;
$red: #c44;
$cyan: #00fbfb;
$cyan-green: #01ccaa;
$purple: #54297b;
$orange: #f5a623;
$success: #7ed321;
$green: #00ffff;
$green: #418841;
$section-bg: #202020;
$section-border-soft: #ffffff22;
$separator-border-soft: #2e2e2e;
