input {
  &:-internal-autofill-selected,
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    background-color: $input-bg !important;
    background-image: none !important;
    box-shadow: 0 0 0 30px $input-bg inset;
    -webkit-box-shadow: 0 0 0 30px $input-bg inset;
    -webkit-text-fill-color: #ffffffa6 !important;
    transition: background-color 5000s ease-in-out 0s;
    color: #ffffffa6 !important;
  }
}

.ant-input-group-addon {
  background-color: #2e2e2e;
}

.ant-picker-panel-container {
  background-color: #2e2e2e;

  .ant-picker-ranges .ant-btn {
    line-height: 1.5715;
  }
}

.ant-picker {
  background-color: #2e2e2e;
}
