.AssetImpressionsHeader {
    &__container {
      display: flex;
      flex-direction: column;
      width: 100%;
      &-row {
        display: flex;
        flex-direction: row;
        margin: 5px;
        > * {
          margin: 0 5px 0 5px;
        }
      }
    }
  }
  