.DictionaryConsentList {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  > :first-child {
    flex-grow: 1;
    flex-shrink: 1;
    overflow-y: scroll;
    overflow-x: auto;
  }

  .ant-pagination {
    flex-grow: 0;
    flex-shrink: 0;
    align-self: flex-end;
    padding: 8px 16px;
    background-color: #3e3e3e;
    border-top-left-radius: 16px;
  }
}
