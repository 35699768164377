.AssetBrowserModal {
  .PageContent {
    margin: 0;
    padding: 0;

    > :first-child {
      padding: 0;
      margin: 0;
    }
  }
}
