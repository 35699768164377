.Dashboard {
  .PageContent :first-child {
    overflow-y: hidden;
    overflow-x: hidden;
  }
}

.dashboard-tile {
  & > img {
    cursor: pointer;
    border-radius: 4px;
    transition: all 0.2s ease-in-out;

    &:hover {
      box-shadow: 4px 4px 2px #000;
      filter: brightness(125%);
    }
    &:active {
      box-shadow: none;
      filter: brightness(75%);
    }
  }
}
