.ant-layout,
.ant-layout-content,
.ant-layout-sider-dark {
  background: none;
}

.ant-layout-sider-children {
  padding-top: 16px;
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.ant-layout-sider-children:first-child {
  flex-grow: 1;
}

.ant-page-header-heading-extra {
  display: flex;
}

.ant-page-header .ant-page-header-back {
  line-height: 32px;
}

.ant-page-header-back-button {
  color: inherit;
}

.ant-form .ant-row .ant-form-item:last-child {
  margin: 0;
}
