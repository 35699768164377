// MacBook Pro
$device-silver: #e2e3e4;
$device-silver-dark: darken($device-silver, 10%);
$device-silver-panel: #0d0d0d;
$device-spacegray: #83878a;
$device-spacegray-dark: darken($device-spacegray, 5%);
$device-spacegray-panel: #0d0d0d;

.device-macbook-pro {
  height: 990px;
  width: 1644px;

  .device-frame {
    background: $device-silver-panel;
    border-radius: 20px;
    box-shadow: inset 0 0 0 2px $device-spacegray-dark;
    height: 951px;
    margin: 0 auto;
    padding: 64px 42px 87px 42px;
    position: relative;
    width: 1364px;

    &::after {
      background: lighten($device-silver-panel, 10%);
      border-radius: 0 0 20px 20px;
      bottom: 2px;
      content: "";
      height: 52px;
      left: 2px;
      position: absolute;
      width: 1360px;
    }

    &::before {
      bottom: 20px;
      color: $device-silver-dark;
      content: "MacBook Pro";
      font-size: 24px;
      height: 32px;
      left: 50%;
      line-height: 32px;
      margin-left: -100px;
      position: absolute;
      text-align: center;
      width: 200px;
      z-index: 1;
    }
  }
  // 1680-by-1050-pixel resolution
  .device-content {
    border: 2px solid lighten($device-silver-panel, 2%);
    border-radius: 2px;
    height: 800px;
    width: 1280px;
    overflow-y: scroll;
    overflow-x: hidden;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */
  }

  .device-content::-webkit-scrollbar {
    /* WebKit */
    width: 0;
    height: 0;
  }

  .device-power {
    background: lighten($device-spacegray, 5%);
    border: solid $device-spacegray-dark;
    border-radius: 2px 2px 0 0;
    border-width: 2px 4px 0 4px;
    height: 24px;
    margin-top: -10px;
    position: relative;
    width: 1644px;
    z-index: 9;

    &::after,
    &::before {
      content: "";
      position: absolute;
    }

    &::after {
      background: lighten($device-spacegray-dark, 5%);
      box-shadow: inset 0 0 4px 2px darken($device-spacegray-dark, 5%);
      border-radius: 0 0 10px 10px;
      height: 20px;
      left: 50%;
      margin-left: -120px;
      top: -2px;
      width: 240px;
    }

    &::before {
      background: darken($device-spacegray-dark, 15%);
      box-shadow: inset 0 -2px 6px 0 darken($device-spacegray-dark, 50%);
      border-radius: 0 0 360px 360px/ 0 0 22px 22px;
      height: 22px;
      left: -4px;
      margin: 0 auto;
      top: 24px;
      width: 1644px;
    }
  }
  // Space Gray edition
  &.device-spacegray {
    .device-frame {
      box-shadow: inset 0 0 0 2px $device-spacegray-dark;
    }

    .device-power {
      background: lighten($device-spacegray, 5%);
      border-color: $device-spacegray-dark;

      &::after {
        background: lighten($device-spacegray-dark, 5%);
        box-shadow: inset 0 0 4px 2px darken($device-spacegray-dark, 5%);
      }

      &::before {
        background: darken($device-spacegray-dark, 15%);
        box-shadow: inset 0 -2px 6px 0 darken($device-spacegray-dark, 50%);
      }
    }
  }
}
