.UserPurchasedProducts {
    .ant-table-content {
        overflow-x: auto;
    }

    .PaymentKeyLink {
        display: inline-block;
        max-width: 130px;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .ColumnAvailableTo {
        min-width: 120px;
    }

    table {
        width: auto;

        th {
            white-space: nowrap;
        }
    }
}
