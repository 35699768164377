.Mobile {
  & .ApplicationMenuItemPreview {
    text-align: center;
    padding-top: 2px;

    &__Icon {
      width: 20px;
      height: 20px;
      margin: 0 auto;
      display: block;
    }

    &__Title {
      margin: 0 4px;
      font-size: 10px;
      font-weight: 500;
      line-height: 14px;
      display: block;
      padding-top: 8px;
    }
  }
}

.Web {
  & .ApplicationMenuItemPreview {
    &__Icon {
      width: 22px;
      height: 22px;
      margin: 0 12px;
    }

    &__Title {
      margin: 0 4px;
      font-size: 16px;
      font-weight: 500;
      line-height: 14px;
    }
  }
}

.AppleTV {
  & .ApplicationMenuItemPreview {
    &__Icon {
      width: 15px;
      height: 15px;
      margin: 0 8px;
    }

    &__Title {
      margin: 0 4px;
      font-size: 10px;
      font-weight: 500;
      line-height: 10px;
    }
  }
}
