@import "@bms/common-ui/src/scss/Variables.scss";

.imagePreview {
  max-width: 100px;
  max-height: 56px;
  cursor: pointer;
}

.imageUrl:hover {
  cursor: pointer;
  text-decoration: underline;
}

.ImageActionButtonContainer {
  display: flex;
  gap: 15px;
}
