.processing-overlay-mask {
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  position: absolute;
  z-index: 10000;
  overflow: hidden;
}

.modal-preview .ant-modal-body {
  & > img,
  & video {
    max-height: 75vh;
    max-width: 75vw;
  }
}
