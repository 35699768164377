.UserPersonalInformationForm {
  &__Avatar {
    width: 100px;
    height: 100px;

    i {
      vertical-align: middle;
      line-height: 100px;
    }

    .icon-user {
      font-size: 48px;
      padding-top: 16px;
    }
  }
  Button {
    margin-top: 10px;
  }
}
