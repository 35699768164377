.ImagePreview {
  display: table-cell;
  width: 100px;
  height: 100px;
  border: 2px solid white;
  border-radius: 5px;
  text-align: center;
  vertical-align: middle;
  padding: 8px;

  img {
    width: 100%;
    height: auto;
    display: block;
  }
}
