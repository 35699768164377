// iPad Pro
$device-silver: #e2e3e4;
$device-silver-dark: darken($device-silver, 10%);
$device-silver-panel: #fff;
$device-gold: #f7e8dd;
$device-gold-dark: darken($device-gold, 20%);
$device-gold-panel: #fff;
$device-rosegold: #facfc9;
$device-rosegold-dark: darken($device-rosegold, 10%);
$device-rosegold-panel: #fff;
$device-spacegray: #9b9ba0;
$device-spacegray-dark: darken($device-spacegray, 10%);
$device-spacegray-panel: #222;

.device-ipad-pro {
  height: 804px;
  width: 560px;

  .device-frame {
    background: $device-silver-panel;
    border-radius: 38px;
    box-shadow: inset 0 0 0 2px $device-silver-dark,
      inset 0 0 0 6px $device-silver;
    height: 804px;
    padding: 62px 25px;
    width: 560px;
  }
  // 2224-by-1668-pixel resolution
  .device-content {
    border: 2px solid #222;
    border-radius: 2px;
    height: 680px;
    width: 510px;
    overflow-y: scroll;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */
  }

  .device-content::-webkit-scrollbar {
    /* WebKit */
    width: 0;
    height: 0;
  }
  // Home button
  .device-header {
    border: 2px solid $device-silver-dark;
    border-radius: 50%;
    bottom: 17px;
    height: 34px;
    left: 50%;
    margin-left: -17px;
    position: absolute;
    width: 34px;
  }

  .device-sensors {
    background: #666;
    border-radius: 50%;
    height: 10px;
    left: 50%;
    margin-left: -5px;
    margin-top: -5px;
    position: absolute;
    top: 34px;
    width: 10px;
  }
  // Gold edition
  &.device-gold {
    .device-frame {
      box-shadow: inset 0 0 0 2px $device-gold-dark,
        inset 0 0 0 6px $device-gold;
    }

    .device-header {
      border-color: $device-gold-dark;
    }
  }
  // Rose Gold edition
  &.device-rosegold {
    .device-frame {
      box-shadow: inset 0 0 0 2px $device-rosegold-dark,
        inset 0 0 0 6px $device-rosegold;
    }

    .device-header {
      border-color: $device-rosegold-dark;
    }
  }
  // Space Gray edition
  &.device-spacegray {
    .device-frame {
      background: $device-spacegray-panel;
      box-shadow: inset 0 0 0 2px $device-spacegray-dark,
        inset 0 0 0 6px $device-spacegray;
    }

    .device-header {
      border-color: $device-spacegray-dark;
    }
  }
}
