.ListItemHighlightComponentPreview {
  position: relative;
  height: 100%;
  width: 100%;

  &--image {
    height: 100%;
    width: 100%;
    img {
      object-fit: fill
    }
  }

  &--info {
    position: absolute;
    bottom: 50px;
    left: 40px;
  }
}
