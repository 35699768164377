.UserResendConfirmationEmailSpin {
  .ant-spin-container {
    &:after {
      background: transparent;
    }
  }
}

.ResendConfirmationEmailForm {
  text-align: left;
}
