.UsersList {
  &__Avatar {
    margin-right: 8px;
  }

  &-column-user {
    display: table;

    &-avatar {
      display: table-cell;
      vertical-align: middle;
    }

    &-name {
      display: table-cell;
      padding: 0 0 0 16px;
      vertical-align: middle;

      p {
        margin-bottom: 4px;
      }
    }
  }
}
