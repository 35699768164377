.AssetDetails {
  height: 100%;
  width: 100%;

  &__PageHeader {
    margin-bottom: 16px;
  }

  &-content {
    padding: 16px 0;
  }

  .ant-tabs .ant-tabs-left-bar .ant-tabs-tab {
    text-align: left;
  }
}
