@mixin scrollbar-theme-dark() {
  &::-webkit-scrollbar {
    height: 6px;
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $gray;
    border-radius: 5px;
  }
}

@mixin scrollbar-theme-gradient() {
  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 5px rgba(255, 255, 255, 0.8);
    border-radius: 3px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 5px rgba(255, 255, 255, 0.3);
    background: $white;
  }
}
