@import "../../scss/Colors.scss";
@import "../../scss/Variables.scss";

.gm-select-container {
  .gm-select__menu {
    background: #2d2d2d; // TODO use ant's variable

    .gm-select__menu-list {
      padding: 20px;
    }
  }

  .anticon-search {
    padding: 8px;
    font-size: 20px;
  }
}
