@import "@bms/common-ui/src/scss/Variables.scss";

.AssetContentList {
  &__TableColumn__Url {
    cursor: pointer;
  }

  &__TableColumn__Url:hover {
    text-decoration: underline;
  }

  &__TableColumn-actions {
    display: flex;
    justify-content: flex-end;
    gap: 4px;
  }
}
