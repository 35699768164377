.hide-now-btn {
  li.ant-picker-now {
    display: none;
  }
}

.AssetForm {
  .ql-editor {
    max-height: 320px;
  }
}
