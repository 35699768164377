.Header {
  display: table;
  padding-bottom: 16px;
  width: 100%;

  &--title {
    display: table-cell;
    width: 50%;
  }

  &--actions {
    display: table-cell;
    text-align: right;
    width: 50%;
  }

  button {
    margin-left: 8px;
  }
}
