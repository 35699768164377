@import "../../scss/Colors.scss";

.section-grid {
  &-item {
    margin-bottom: 24px;
    padding-bottom: 24px;
    border-bottom: 1px solid $separator-border-soft;
  }

  &-item {
    &-header-desc {
      min-width: 150px;
      max-width: 300px;
    }

    &:last-child {
      border-bottom-width: 0;
      margin-bottom: 0;
    }

    &-opaque-margin {
      position: absolute;
      right: 0;
      top: 0;
      width: 16px;
      height: 100%;
      background-image: linear-gradient(90deg, #00000000 0, $section-bg 8px);
    }

    &-content {
      transition: filter 0.6s;
      padding: 16px;
      border-radius: 4px;
      border: solid 1px $section-border-soft;
      background-color: $section-bg;
      overflow: hidden;

      &::before {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 4px;
        border-radius: 4px 4px 0 0;
        display: block;
        opacity: 0;
        transition: opacity 0.3s;
        background-image: linear-gradient(
          90deg,
          $primary 500px,
          rgb(41, 35, 35) 500px
        );
        background-size: 2000px;
        background-repeat: repeat-x;
        content: "";
      }
    }

    &-processing {
      filter: grayscale(50%) brightness(90%);

      @keyframes processing-bar {
        from {
          background-position-x: 0;
        }

        to {
          background-position-x: 2000px;
        }
      }

      &::before {
        opacity: 1;
        animation: processing-bar 2s infinite linear;
      }

      &::after {
        position: absolute;
        top: 0px;
        width: 100%;
        height: 100%;
        display: block;
        content: "";
      }
    }
  }
}
